
import { Component, Vue } from 'vue-property-decorator'
import { wareHousingList } from '@/types/assetScheduling'

@Component
export default class ScheduleAdd extends Vue {
  private info: wareHousingList = {
    orderDate: '',
    projectId: '',
    remarks: '',
    assetList: []
  }

  private page = 1
  private size = 10
  private total = 0

  private rules = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: ['blur', 'change'] }
    ],
    orderDate: [
      { required: true, message: '请输入入库日期', trigger: ['blur', 'change'] }
    ]
  }

  // 是否退还
  get isRefund () {
    return (this.$route.path.split('/').length > 2 && this.$route.path.split('/')[2].indexOf('refund') !== -1) || this.$route.query.isRefund === '2'
  }

  private title = this.isRefund ? '退还' : '领用'

  created () {
    this.getData()
  }

  getData () {
    this.$axios.get(this.isRefund ? this.$apis.assetScheduling.selectAssetReturnByOrderId : this.$apis.assetScheduling.selectAssetReceiveByOrderId, {
      orderId: this.$route.params.orderId
    }).then(res => {
      this.info = res || []
      this.getList()
    })
  }

  getList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetImageByPage, {
      page: this.page,
      size: this.size,
      orderId: this.$route.params.orderId
    }).then(res => {
      this.info.assetList = res.list || []
      this.total = res.total || 0
    })
  }
}
